@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;700&family=Raleway:wght@700&display=swap');

* {
  box-sizing: border-box;
}

:root {
  --white: #ffffff;
  --black: #000000;
  --gray: #323232;
  --lightgray: whitesmoke;
}

html, body {
  margin: 0;
  height: 100%;
}

body {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--white);
  font-size: 1rem;
}

a {
  color: var(--gray);
  font-weight: bold;
  text-decoration: none;
  transition: .4s;
  display: inline-block;
}

a:hover, 
a:focus {
  color: var(--black);
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  position: fixed;
  background-color: white;
  width: 100%;
  height: 80px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}

.header h1 {
  font-family: 'Raleway', sans-serif;
  color: black;
  font-weight: 700;
  font-size: 1.5rem;
}

.header h1:hover, 
.header h1:focus {
  color: var(--gray);
}

.header input {
  outline: none;
  border: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: whitesmoke;
  font-family: 'Open Sans', sans-serif;
  color: var(--gray);
  padding: .8em 1.2em;
  width: 200px;
}

.header button {
  outline: none;
  border: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: whitesmoke;
  font-family: 'Open Sans', sans-serif;
  color: var(--gray);
  font-weight: bold;
  padding: .8em 1.2em;
  cursor: pointer;
  transition: .4s;
}

.header button:hover {
  color: var(--black);
}

.search {
  margin: .5em;
}

.gallery-page {
  padding-top: 6em;
  margin: 0 auto;
  width: 75%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: 1fr;
  gap: 1em;
  justify-items: center;
}

.image-small {
  width: 300px;
  height: 300px;
  object-fit: cover;
  transition: .4s;
  animation-name: animation;
  animation-duration: 3s;
  animation-iteration-count: 1;
  position: relative;
  display: inline-block;
}

.image-small:hover,
.image-small:focus {
  filter: brightness(80%);
  cursor: pointer;
}

.image-page {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  padding-top: 6em;
  width: 75%;
  height: 100%;
}

.image-large {
  width: 100%;
  height: auto;
  display: block;
}

figcaption {
  font-size: .9rem;
  color: var(--gray);
  text-align: center;
}

.image-details {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 1;
  margin-top: 1em;
}

.image-exif {
  line-height: .8;
}

.btn {
  outline: none;
  border: none;
  border-radius: 10px;
  background-color: var(--lightgray);
  font-family: 'Open Sans', sans-serif;
  color: var(--gray);
  font-weight: bold;
  padding: .8em 1.2em;
  cursor: pointer;
  transition: .4s;
  display: inline-block;
}

.btn:hover,
.btn:focus {
  color: var(--black);
  transform: scale(1.05);
}

.user-details {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

.avatar-small {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: .5em;
  transition: .4s;
}

.avatar-small:hover {
  opacity: .7;
  cursor: pointer;
}

.avatar-large {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.user-page {
  padding-top: 6em;
  text-align: center;
  margin: 0 auto;
  width: 75%;
  height: 100%;
}

.user-link {
  font-size: 1.1rem;
  display: inline-block;
}

.italic {
  font-style: italic;
}

.center {
  text-align: center;
}

.highlight {
  background-color: var(--lightgray);
  display: inline;
  padding: .5em;
  text-transform: uppercase;
  color: var(--gray);
  letter-spacing: 1px;
  font-size: .9rem;
}

.footer {
  text-align: center;
  font-size: .8rem;
  margin-top: auto;
}

.footer a {
  font-weight: 500;
}

@keyframes animation {
  0%   {left:0px; top:100px;}
  100%  {left:0; top:0px;}
}

@media (max-width: 700px){
  .container {
    flex-direction: column;
  }
  .gallery-page {
    width: 60%;
  }
  .image-page {
    flex-direction: column;
  }
  .image-details {
    margin: 0 auto;
    text-align: center;
    width: 50%;
    align-items: center;
    justify-content: center;
    padding-top: 2em;
  }
}